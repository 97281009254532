.ccardHoverable:hover {
    /* background-color: var(--accentColor); */
    transform: scale(1.01);
}

.invertedccard {
    background-color: var(--backgroundSecondaryColor);
    -webkit-box-shadow: var(--shadowColor) 0px 1px 1px 0px; 
    box-shadow: var(--shadowColor) 0px 1px 1px 0px;
}

.ccard {
    background-color: var(--backgroundMainColor);
    -webkit-box-shadow: var(--shadowColor) 0px 1px 1px 0px; 
    box-shadow: var(--shadowColor) 0px 1px 1px 0px;
}

.ccardclickable {
    cursor: pointer;
}