@keyframes arrows {
    0%,
    35% {
        transform: translateY(0);
    }

    15%,
    20% {
        transform: translateY(-20px);
    }
}
@keyframes smallArrows {
    0%,
    35% {
        transform: translateY(0);
    }

    15%,
    20% {
        transform: translateY(-10px);
    }
}

.jumper {
    --delay: 0s;
    animation: arrows 2s var(--delay) infinite ease-in;
}

.smallJumper {
    --delay: 0s;
    animation: smallArrows 2s var(--delay) infinite ease-in;
}