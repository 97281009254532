select {
    padding: 10px;
    padding-right: 25px;
    border-style: 'solid';
    background-color: var(--backgroundMainColor);
    border-radius: 10px;
    min-height: 40px;
    border-width: 0px;
    -webkit-box-shadow: var(--shadowColor) 0px 1px 1px 0px; 
    box-shadow: var(--shadowColor) 0px 1px 1px 0px;
    font-family: var(--fontMain);;
    color: var(--textMainColor)
}
.cinput__input {
    box-shadow: none;
}
.fds {
    border-right: 16px solid transparent
}