.toolbarClassName {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 0px;
    border-width: 0px;
    background-color: var(--backgroundMainColor);
    -webkit-box-shadow: var(--shadowColor) 0px 1px 1px 0px; 
    box-shadow: var(--shadowColor) 0px 1px 1px 0px;
    font-family: var(--fontMain);;
}

.rdw-dropdown-wrapper {
    background-color: var(--backgroundMainColor);
    color: var(--textMainColor);
}

.rdw-option-wrapper {
    background-color: var(--backgroundMainColor);
    color: var(--textMainColor);
}

img {
    filter: var(--textMainFilters); /* shinanegains */
}

.editorClassName{
    flex: 1;
    height: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-width: 0px;
    margin-top: 0px;
    -webkit-box-shadow: var(--shadowColor) 0px 1px 1px 0px; 
    box-shadow: var(--shadowColor) 0px 1px 1px 0px;
    color: var(--textMainColor);
    background-color: var(--backgroundMainColor);
    padding: 5px;
    margin-bottom: 10px;
    font-family: var(--fontMain);;
    font-size: 14px;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
    border-radius: 2.5px;
    border-width: 0px;
    -webkit-box-shadow: var(--shadowColor) 0px 1px 1px 0px; 
    box-shadow: var(--shadowColor) 0px 1px 1px 0px;
}
