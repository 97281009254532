/* 
    urlaub: #a8b1ce
    today: #5a81fa
    Beworben: #ffeadd
    angenommen: #feae7f
    active: #2c3d8f
*/
/*
#5a81fa accent color 
#1f1f1f headline
#2c3d8f (Dunkelblau)
#6a6e83 text  
#cddeff (Hellblau)
#f2f5ff (Wolkenblau)
#a8b1ce (Graublau)
#f8f9fd Background Primary 
#ffffff Background Secondary
#feae7f orange contrast
#ffeadd peach contrast
*/


.react-calendar {
    flex: 1;
    width: 100%;
    background: var(--backgroundMainColor);
    font-family: var(--fontMain);
    line-height: 1.125em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* .react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
} */

.react-calendar button {
    margin: 0;
    outline: none;
    
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar button abbr {
    max-width: 52px;
    max-height: 52px;
    border-radius: 26px;
    width: 100%;
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: auto;
}

/* Tiles */
.react-calendar__month-view__weekNumbers {
    color: var(--textMainColor);
    max-width: 40px;
}
.react-calendar__month-view__weekdays__weekday {
    color: var(--textMainColor);
}

.react-calendar__tile {
    margin: auto;
    border-radius: 5px;
    border: 0px;
    background: none;
    text-align: center;
    margin: auto;
    line-height: 80px;
    height: 80px;
    color: var(--textMainColor);
    flex: 0;
}

.react-calendar__tile:disabled {
    background-color: var(--borderColor);
}

.react-calendar__tile--now abbr{
    background: transparent;
    border: solid 2px var(--accentColor);
}

.react-calendar__tile--active abbr,
.react-calendar__tile--active:enabled:hover abbr,
.react-calendar__tile--active:enabled:focus abbr{
    background-color: var(--accentColor);
    color: var(--textAccentColor);
}

/* navigation */

.react-calendar__navigation button {
    min-width: 44px;
    border: 0px;
    background: none;
    color: var(--textMainColor);
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: var(--borderColor);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--backgroundMainColor);
}

/* month pick / year pick */

.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background-color: var(--accentColor);
    color: var(--textAccentColor);
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: var(--backgroundMainColor);
}

.react-calendar__month-view__days {
    row-gap: 4px;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: var(--warningColor);
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--borderColor);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.withDot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    background-color: transparent;
}

.endPiece {
    border-color: var(--accentColor);
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-width: 1px;
    border-style: solid;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.startPiece {
    border-color: var(--accentColor);
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 0px;
    border-style: solid;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.centerPiece {
    border-color: var(--accentColor);
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    border-radius: 0px;
}