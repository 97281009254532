body {
    --backgroundMainColor: #ffffff;
    --backgroundSecondaryColor: #f8f9fd;
    --backgroundTertiaryColor: #f5f5f5;
    --borderColor: #cdced5;
    --textMainColor: #1f1f1f;
    --textSecondaryColor: #6a6e83;
    --textTertiaryColor: #a7aeb3;
    --accentColor: #5a81fa;
    --textAccentColor: #f8f9fd;
    --fontMain: Poppins;
    --fontThin: Poppins;
    --errorColor: #e85b55;
    --warningColor: #feae7f;
    --successColor: #39d08b;
    --shadowColor: rgba(0, 0, 0, 0.18)
}

.dark {
    --backgroundMainColor: #282c34;
    --backgroundSecondaryColor: #21252b;
    --backgroundTertiaryColor: #474646;
    --borderColor: #404042;
    --textMainColor: #d4d2d2;
    --textSecondaryColor: #a1a7c7;
    --textTertiaryColor: #656c70;
    --accentColor: #5a81fa;
    --textAccentColor: #d4d2d2;
    --fontMain: Poppins;
    --fontThin: Poppins;
    --errorColor: #e85b55;
    --warningColor: #feae7f;
    --successColor: #39d08b;
    --textMainFilters: invert(80%) sepia(0%) saturate(368%) hue-rotate(190deg) brightness(104%) contrast(95%);
    --shadowColor: rgba(0, 0, 0, 0.18)
}

.timePloyees {
    --backgroundMainColor: #FFF;
    --backgroundSecondaryColor: #F4F2EE;
    --backgroundTertiaryColor: #f3fff9;
    --borderColor: #cdced5;
    --textMainColor: #222b54;
    --textSecondaryColor: #0a3c5f;
    --textTertiaryColor: #a7aeb3;
    --accentColor: #62c1c5;
    --textAccentColor: #fafcfb;
    --fontMain: Lota Grotesque;
    --fontThin: Lota Grotesque;
    --errorColor: #f44336;
    --warningColor: #b98a20;
    --successColor: #519c58;
    --shadowColor: rgba(0, 0, 0, 0.18)
}

.darkTimePloyees {
    --backgroundMainColor: #191919;
    --backgroundSecondaryColor: #111111;
    --backgroundTertiaryColor: #474646;
    --borderColor: #404042;
    --textMainColor: #fafcfb;
    --textSecondaryColor: #a1a7c7;
    --textTertiaryColor: #656c70;
    --accentColor: #62c1c5;
    --textAccentColor: #fafcfb;
    --fontMain: Lota Grotesque;
    --fontThin: Lota Grotesque;
    --errorColor: #f44336;
    --warningColor: #b98a20;
    --successColor: #519c58;
    --textMainFilters: invert(98%) sepia(94%) saturate(11%) hue-rotate(76deg) brightness(100%) contrast(98%);
    --shadowColor: rgba(111, 111, 111, 0.2)
}

.lieblingsjob {
    --backgroundMainColor: #FFF;
    --backgroundSecondaryColor: #e8edeb;
    --backgroundTertiaryColor: #f3fff9;
    --borderColor: #cdced5;
    --textMainColor: #060815;
    --textSecondaryColor: #0a3c5f;
    --textTertiaryColor: #a7aeb3;
    --accentColor: #99c14d;
    --textAccentColor: #fafcfb;
    --fontMain: PlusJakartaSans;
    --fontThin: PlusJakartaSans;
    --errorColor: #db4049;
    --warningColor: #ffce4d;
    --successColor: #6AC69E;
    --shadowColor: rgba(0, 0, 0, 0.18)
}

.lieblingsjobDark {
    --backgroundMainColor: #191919;
    --backgroundSecondaryColor: #111111;
    --backgroundTertiaryColor: #474646;
    --borderColor: #404042;
    --textMainColor: #fafcfb;
    --textSecondaryColor: #a1a7c7;
    --textTertiaryColor: #656c70;
    --accentColor: #99c14d;
    --textAccentColor: #fafcfb;
    --fontMain: PlusJakartaSans;
    --fontThin: PlusJakartaSans;
    --errorColor: #db4049;
    --warningColor: #ffce4d;
    --successColor: #6AC69E;
    --textMainFilters: invert(98%) sepia(94%) saturate(11%) hue-rotate(76deg) brightness(100%) contrast(98%);
    --shadowColor: rgba(111, 111, 111, 0.2)
}

input {
    outline-color: var(--accentColor);
}
select {
    outline-color: var(--accentColor);
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../../assets/fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(../../assets/fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'DM Sans';
    src: local('DMSans'), url(../../assets/fonts/DMSans-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins-SemiBold'), url(../../assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Lota Grotesque';
    src: local('LotaGrotesque'), url(../../assets/fonts/LotaGrotesque-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Lota Grotesque';
    font-style: normal;
    font-weight: 500;
    src: local('LotaGrotesque-SemiBold'), url(../../assets/fonts/LotaGrotesque-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'PlusJakartaSans';
    src: local('PlusJakartaSans'), url(../../assets/fonts/PlusJakartaSans-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'PlusJakartaSans';
    font-style: normal;
    font-weight: 500;
    src: local('PlusJakartaSans-SemiBold'), url(../../assets/fonts/PlusJakartaSans-SemiBold.ttf) format('truetype');
}

*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-track {
    background-color: var(--borderColor);
    border: 1px solid var(--textTertiaryColor);
}

*::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--accentColor);
}