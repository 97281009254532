
/* .ctablerow {
    background-color: var(--backgroundMainColor);
} */

/* .ctablerow:hover {
    background-color: var(--accentColor);
} */

.ctablerow:before {
    content:'\A';
    position:absolute;
    width:100%; height:100%;
    top:0; left:0;
    background:var(--accentColor);
    opacity:0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}
.ctablerow:before {
    opacity:0;
}
.ctablerow:hover:before {
    opacity:0.1;
}